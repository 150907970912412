import { CertificateItem } from "@/store/modules/CertificateItem";

function convertToCertificateItemFromApi(object) {
  return {
    certificateItemId: object.certificate_item_id,
    packingList: object.packing_list,
    purchaseOrderNumber: object.purchase_order_number,
    heatnumber: object.heatnumber,
    certificateFile: object.certificate_file,
    fileUrl: object.file_url,
    note: object.note,
    createdAt: object.created_at,
    uploadedAt: object.uploaded_at,
    uploadedBy: object.uploaded_by,
    emailId: object.email_id,
    emailDate: object.email_date,
    emailSender: object.email_sender,
    assigned: object.assigned,
  } as CertificateItem;
}

export default convertToCertificateItemFromApi;
